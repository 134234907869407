import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import combinedReducers from '../containers/combinedReducers';

let initialState = {}; 

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combinedReducers,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;