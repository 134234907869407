export const SET_LOG_IN_OUT = 'SET_LOG_IN_OUT';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USERNAME = 'SET_USERNAME';

export const POST_TASK = 'POST_TASK';
export const GET_TASKS = 'GET_TASKS';
export const DEL_TASK = 'DEL_TASK';
export const PUT_TASK = 'PUT_TASK';

